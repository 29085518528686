import { Modal } from '@sqs/rosetta-elements';
import { ColumnDef, Table } from '@sqs/rosetta-experimental';
import { Box, Button, Flex, Text } from '@sqs/rosetta-primitives';
import { useTheme } from '@sqs/rosetta-styled';
import { sizes } from '@sqs/rosetta-tokens';
import React, { useEffect, useMemo, useState } from 'react';
import { T } from '../../i18n';
import { Enterprise } from '../../models/Enterprise';
import { SubUser } from '../../models/SubUser';
import { selectEnterprise } from '../../stores/currentUser';
import { RootState } from '../../stores/rootReducer';
import { useAppDispatch, useAppSelector } from '../../stores/store';
import { fetchSubUsers, selectByParentId } from '../../stores/subUsers';
import { ImpersonateLink } from '../common';
import { AcuityLoader } from '../common/AcuityLoader';
import styles from './SubUsersList.less';

interface SubUsersListProps {
  readonly subUsersCount: number;
  readonly ownerEmail: string;
  readonly ownerId: number;
}

export function SubUsersList({ subUsersCount, ownerEmail, ownerId }: SubUsersListProps) {
  const [modalVisible, setModalVisible] = useState(false);

  const { enterprise, subUsers, isLoading } = useAppSelector((state: RootState) => ({
    enterprise: selectEnterprise(state.currentUser) as Enterprise,
    subUsers: selectByParentId(state.subUsers, ownerId),
    isLoading: state.subUsers.isLoading
  }));

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (modalVisible && enterprise && !subUsers.length) {
      dispatch(fetchSubUsers(enterprise.id, ownerId));
    }
  }, [modalVisible, enterprise, subUsers, dispatch, ownerId]);

  const columns = useMemo<ColumnDef<SubUser>[]>(
    () => [
    {
      accessorKey: 'username'
    },
    {
      accessorKey: 'id',
      cell: (cell) => {
        const enterpriseId = enterprise.id;
        const row = cell.row.original as SubUser;
        return <ImpersonateLink enterpriseId={enterpriseId} userId={row.id} />;
      }
    }],

    [enterprise]
  );

  const theme = useTheme();

  return (
    <>
      <Text.Body css={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setModalVisible(true)}>
        {subUsersCount}
      </Text.Body>
      <Modal.Transition>
        {modalVisible &&
        <Modal onRequestClose={() => setModalVisible(false)}>
            <Modal.Overlay />
            <Modal.Position position="center">
              <Modal.Dialog.ResponsiveProvider maxWidth={400} aspectRatio={1 / 1.25}>
                <Modal.Dialog>
                  <Box px={4} py={4} width={sizes.sizes[750]} height={sizes.sizes[800]}>
                    <Button.Tertiary size="1" onClick={() => setModalVisible(false)}>
                      Close
                    </Button.Tertiary>
                    <Text.Title>
                      <T project="enterprise-dashboard">{"Users"}</T>
                    </Text.Title>
                    <Text.Subtitle color={theme.colors.gray['400']}>{ownerEmail}</Text.Subtitle>
                    {isLoading &&
                  <Flex height="50%" justifyContent="center" alignItems="center">
                        <AcuityLoader />
                      </Flex>}

                    {!isLoading &&
                  <Box className={styles.subUsersList}>
                        <Table columns={columns} data={subUsers}>
                          <Table.List />
                        </Table>
                      </Box>}

                  </Box>
                </Modal.Dialog>
              </Modal.Dialog.ResponsiveProvider>
            </Modal.Position>
          </Modal>}

      </Modal.Transition>
    </>);

}