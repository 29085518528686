import { Chip, Tabs, TabsOptionProps } from '@sqs/rosetta-elements';
import { Search } from '@sqs/rosetta-icons';
import { Box, Button, Flex, Text } from '@sqs/rosetta-primitives';
import { useTheme } from '@sqs/rosetta-styled';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FEATURE_FLAGS } from '../../const/feature-flags';
import { T, t } from '../../i18n';
import { selectAll as selectAllInvitedOrgUsers } from '../../stores/invitedOrgUsers';
import { RootState } from '../../stores/rootReducer';
import { useAppSelector } from '../../stores/store';
import { CreateUser } from '../CreateUser/CreateUser';
import { FeatureFlagContext } from '../FeatureFlagProvider';
import { PageHeader } from '../PageHeader';
import { UsersListResults } from './UsersListResults';

interface UsersProps {
  readonly showInvitedUsers?: boolean;
}

export const Users = ({ showInvitedUsers }: UsersProps) => {
  const { fontSizes, space } = useTheme();
  const navigate = useNavigate();
  const [showSearch, setShowSearch] = useState(false);
  const [selectedTab, setSelectedTab] = useState<string>(showInvitedUsers ? '/users/invited' : '/users');
  const { flagEnabled } = useContext(FeatureFlagContext);

  // TODO: Remove with AE-634
  const filterUsersByInviteStatus = flagEnabled(FEATURE_FLAGS.ENTERPRISE_SEPARATE_INVITED_USER);

  const { expiredInvites } = useAppSelector((state: RootState) => {
    return {
      expiredInvites: selectAllInvitedOrgUsers(state.invitedOrgUsers).filter((user) => user.status === 'EXPIRED').
      length
    };
  });

  const mobileSearchButton = () => {
    return (
      <Button.Tertiary key="show-search" type="button" onClick={() => setShowSearch(!showSearch)}>
        <Search />
      </Button.Tertiary>);

  };

  // TODO: Disable this functionality if org has SSO enabled
  const tabOptions: TabsOptionProps[] = [
  {
    value: '/users',
    label: t("Active", {}, { project: 'enterprise-dashboard' }),
    sx: {
      marginRight: space[5],
      fontSize: fontSizes[4]
    }
  },
  {
    value: '/users/invited',
    label:
    <Flex>
          <Text.Body as={'div'}>
            <T project="enterprise-dashboard">{"Invited"}</T>
            {expiredInvites > 0 ?
        <Chip usage="badge" sx={{ marginLeft: '8px' }} status="error" label={expiredInvites} /> :

        <></>}

          </Text.Body>
        </Flex>,

    sx: {
      marginLeft: space[5],
      marginRight: space[5],
      fontSize: fontSizes[4]
    }
  }];


  const changeTab = (v: string) => {
    setSelectedTab(v);
    navigate(v);
  };

  return (
    <Box position="relative">
      <PageHeader
        actionButton={<CreateUser />}
        mobileActions={[mobileSearchButton(), <CreateUser key="create-user-btn" />]}
        title={t("Users", {}, { project: 'enterprise-dashboard' })} />

      {filterUsersByInviteStatus &&
      <Box mb={7}>
          <Tabs
          htmlAttributes={{ css: { '&:before': { height: 0 } } }}
          options={tabOptions}
          value={selectedTab}
          onChange={changeTab} />

        </Box>}

      <UsersListResults showSearch={showSearch} invitedUsersOnly={showInvitedUsers} />
    </Box>);

};