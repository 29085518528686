import { Checkbox, Divider } from '@sqs/rosetta-elements';
import { CrossLarge, Search } from '@sqs/rosetta-icons';
import { Box, Button, Flex, Text, Touchable } from '@sqs/rosetta-primitives';
import { useTheme } from '@sqs/rosetta-styled';
import throttle from 'lodash/throttle';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { usePlatformBreakpoint } from '../../hooks/usePlatformBreakpoint';
import { PageInfo, PageInfoQueryWithSearch } from '../../models/PaginatedResponse';
import { SearchSelectItem } from '../../models/SearchSelectItem';
import { selectEnterprise } from '../../stores/currentUser';
import { RootState } from '../../stores/rootReducer';
import { useAppSelector } from '../../stores/store';
import { t, T } from '../../i18n';
import { preventSubmit } from '../../utils/keyboard';
import { AcuityLoader } from './AcuityLoader';
import { AcuityTextField } from './AcuityTextField';

interface SelectItemForm {
  allItems: boolean;
  items?: SearchSelectItem[];
  totalItems: number;
}

interface SearchAndSelectProps {
  readonly keyId?: number;
  readonly searchPlaceholder: string;
  readonly allCheckText: string;
  readonly emptyText: string;
  readonly listItems: (
  enterpriseId: number,
  options?: PageInfoQueryWithSearch) =>
  Promise<{data: SearchSelectItem[];pageInfo: PageInfo;}>;
  readonly showVariantSelector?: boolean;
  readonly preloadedData?: SearchSelectItem[];
  readonly preloadedDataTotal?: number;
  readonly providedContainerMaxHeight?: string;
  readonly additionalPaddingBottom?: string;
}

const SEARCH_SELECT_LIMIT = 15;

interface LoadAdditionalItemsOptions {
  search?: string;
  replaceData?: boolean;
  page?: number;
}

export const SearchAndSelect = ({
  allCheckText,
  emptyText,
  listItems,
  preloadedData,
  providedContainerMaxHeight,
  additionalPaddingBottom,
  searchPlaceholder,
  showVariantSelector,
  keyId
}: SearchAndSelectProps) => {
  const { fontSizes } = useTheme();
  const { isMobile } = usePlatformBreakpoint();
  const [searchValue, setSearchValue] = useState<string>('');
  const itemContainerRef = useRef<HTMLDivElement>(null);
  const [itemPage, setItemPage] = useState<number>(0);
  const [isFetchingItems, setIsFetchingItems] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [itemIdStore, setItemStore] = useState<Set<number>>(new Set([]));
  const initialRender = useRef(false);

  const searchSelectLimit = providedContainerMaxHeight ? SEARCH_SELECT_LIMIT * 2 : SEARCH_SELECT_LIMIT;

  const { enterpriseId } = useAppSelector((state: RootState) => ({
    enterpriseId: selectEnterprise(state.currentUser)?.id
  }));

  const { control, setValue, watch, register } = useFormContext<SelectItemForm>();

  register('allItems');
  register('totalItems');
  const { fields, append, replace } = useFieldArray({
    control,
    name: 'items'
  });

  const allItems = !!watch('allItems');
  const itemListCount = itemIdStore.size;
  const totalItems = watch('totalItems');

  const addItemToStore = (id: number) => {
    setItemStore((current) => current.add(id));
  };
  const removeItemFromStore = (id: number) => {
    setItemStore((current) => {
      current.delete(id);
      return new Set(current);
    });
  };

  const renderClearButton = useMemo(() => {
    if (!searchValue) {
      return null;
    }
    return (
      <Touchable.Element.Icon
        type="button"
        onClick={() => {
          setSearchValue('');
        }}>

        <CrossLarge />
      </Touchable.Element.Icon>);

  }, [searchValue]);

  const fetchItems = (pageInfo: PageInfoQueryWithSearch = {}, replaceData?: boolean) => {
    if (enterpriseId) {
      setIsFetchingItems(true);
      listItems(enterpriseId, pageInfo).then((res) => {
        const itemsFetched = res.data;
        if (replaceData) {
          replace(itemsFetched);
        } else {
          append(itemsFetched);
        }
        setValue('totalItems', res.pageInfo.total);
        setIsFetchingItems(false);
      });
    }
  };

  const loadAdditionalItems = throttle(({ search, page, replaceData } = {}) => {
    if (!isFetchingItems) {
      const limit = SEARCH_SELECT_LIMIT;
      const offset = (page || 0) * limit;
      const options: PageInfoQueryWithSearch = { limit, offset };
      if (search) {
        options.search = search;
      }
      fetchItems(options, replaceData);
    }
  }, 300);

  /**
   * Only trigger a search after a 500ms timeout of not typing
   * When we search, make sure the itemPage is 0 to ensure we get the first page of results
   * TODO: This can be improved upon for items where results are slow to appear and typing is at a slow rate
   */
  useEffect(() => {
    if (initialRender.current) {
      const searchTimeout = setTimeout(() => {
        setLastScrollTop(0);
        setItemPage(0);
        loadAdditionalItems({ search: searchValue, replaceData: true });
      }, 500);
      return () => {
        clearTimeout(searchTimeout);
      };
    }

    initialRender.current = true;
  }, [searchValue]);

  useEffect(() => {
    if (fields.filter((f) => f.checked).length === 0) {
      if (preloadedData && preloadedData.length > 0) {
        append(preloadedData);
      } else {
        fetchItems({ limit: searchSelectLimit, offset: 0 }, true);
      }
    }
  }, [keyId]);

  const selectAllAction = () => {
    setValue('allItems', true, { shouldDirty: true });
    fields.forEach((f, i) => {
      setValue(`items.${i}.checked`, true, { shouldDirty: true });

      if (showVariantSelector) {
        addItemToStore(f.itemId);
      }
    });
  };

  const deselectAllAction = () => {
    setValue('allItems', false, { shouldDirty: true });
    if (showVariantSelector) {
      [...itemIdStore].forEach((item) => {
        removeItemFromStore(item);
      });
    }
    fields.forEach((f, i) => {
      setValue(`items.${i}.checked`, false, { shouldDirty: true });
    });
  };

  useEffect(() => {
    if (!showVariantSelector) {
      if (allItems) {
        selectAllAction();
      } else {
        deselectAllAction();
      }
    }
  }, [allItems, fields]);

  /**
   * Load more items when we reach the bottom of the item list. If the user tries to scroll up, return early.
   */
  const scrollLoadMoreItems = () => {
    const itemContainer = itemContainerRef.current;
    if (itemContainer && fields.length < totalItems && !allItems) {
      // if the user scrolls up, don't do anything
      if (itemContainer.scrollTop < lastScrollTop) {
        return;
      }
      setLastScrollTop(itemContainer.scrollTop <= 0 ? 0 : itemContainer.scrollTop);
      if (itemContainer.scrollTop + itemContainer.offsetHeight >= itemContainer.scrollHeight) {
        loadAdditionalItems({ page: itemPage + 1 });
        setItemPage(itemPage + 1);
      }
    }
  };

  const containerMaxHeight = providedContainerMaxHeight ? providedContainerMaxHeight : '';
  const itemsBoxPaddingBottom = additionalPaddingBottom ? additionalPaddingBottom : 3;
  return (
    <Flex flexDirection="column" maxHeight={containerMaxHeight}>
      <AcuityTextField
        inputProps={{
          placeholder: searchPlaceholder,
          onChange: setSearchValue,
          onKeyDown: preventSubmit,
          value: searchValue,
          fontSize: fontSizes[4],
          disabled: totalItems === 0
        }}
        interiorPost={renderClearButton}
        interiorPre={<Search />} />

      {showVariantSelector ?
      <Flex my={2} justifyContent="space-between" alignItems="center">
          <Flex flexGrow={2}>
            <Text.Caption fontWeight={500} fontSize={fontSizes[3]}>
              {t("{items} items selected",

            { items: allItems ? totalItems : itemListCount }, {
              project: 'enterprise-dashboard' })}

            </Text.Caption>
          </Flex>
          <Flex>
            <Button.Tertiary color="black" mr={8} size="medium" onClick={selectAllAction}>
              <T project="enterprise-dashboard">{"Select all"}</T>
            </Button.Tertiary>
            <Button.Tertiary color="black" size="medium" onClick={deselectAllAction}>
              <T project="enterprise-dashboard">{"Deselect all"}</T>
            </Button.Tertiary>
          </Flex>
        </Flex> :

      <Flex justifyContent="space-between" alignItems="flex-end" mb={2}>
          <Text.Body as="label" css={{ display: 'inline-flex' }} mt={2}>
            <Checkbox
            name="allItems"
            onChange={(v: boolean) => {
              setValue('allItems', v, { shouldDirty: true });
            }}
            checked={allItems}
            value={true}
            mr={2} />

            {allCheckText}
          </Text.Body>
          {itemListCount > 0 || allItems ?
        <Text.Caption fontWeight={500}>
              {t("{items}  selected",

          { items: allItems ? totalItems : itemListCount }, {
            project: 'enterprise-dashboard' })}

            </Text.Caption> :
        null}
        </Flex>}

      <Divider />
      {totalItems > 0 ?
      <Box
        ref={itemContainerRef}
        mt={2}
        minHeight="300px"
        maxHeight={containerMaxHeight ? '' : '300px'}
        overflowY={!showVariantSelector && allItems ? 'hidden' : 'scroll'}
        onScroll={() => scrollLoadMoreItems()}
        pb={itemsBoxPaddingBottom}>

          {fields.map((item, i) => {
          const isChecked = watch(`items.${i}.checked`) || itemIdStore.has(item.itemId) || allItems;

          const isDisabled = () => {
            if (showVariantSelector) {
              return isFetchingItems;
            }
            return allItems || isFetchingItems;
          };

          const textColor = isDisabled() ? 'gray.600' : '';
          return (
            <div key={item.id}>
                <Text.Body as="label" display="inline-flex" color={textColor} mt={2}>
                  <Checkbox
                  name="itemList"
                  onChange={(v: boolean) => {
                    if (!isDisabled() && !v) {
                      setValue('allItems', false, { shouldDirty: true });
                    }
                    setValue(`items.${i}.checked`, v, { shouldDirty: true });
                    if (v) {
                      addItemToStore(item.itemId);
                    } else {
                      removeItemFromStore(item.itemId);
                    }
                  }}
                  isDisabled={isDisabled()}
                  checked={isChecked}
                  value={item.id}
                  mr={3} />

                  {item.name}
                </Text.Body>
              </div>);

        })}
        </Box> :

      <Text.Body fontStyle="italic">{emptyText}</Text.Body>}

      {isFetchingItems &&
      <Box position="absolute" bottom="15px" left="50%" translate="-50%">
          <AcuityLoader />
        </Box>}

      {!isMobile && <Divider />}
    </Flex>);

};