import { Dropdown } from '@sqs/rosetta-compositions';
import { Checkbox, Divider } from '@sqs/rosetta-elements';
import { Box, Button, Flex, Text } from '@sqs/rosetta-primitives';
import { useTheme } from '@sqs/rosetta-styled';
import React, { ChangeEvent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  updateUserInSchedulingInstance,
  AddUserToSchedulingInstanceData,
  fetchSchedulingInstanceCalendars } from
'../../apis/EnterpriseApiV2';
import { usePlatformBreakpoint } from '../../hooks/usePlatformBreakpoint';
import { PageInfoQueryWithSearch } from '../../models/PaginatedResponse';
import { SearchSelectItem } from '../../models/SearchSelectItem';
import { selectEnterprise } from '../../stores/currentUser';
import { sendErrorMessage, sendSuccessMessage } from '../../stores/messages';
import { RootState } from '../../stores/rootReducer';
import { fetchSchedulingInstances, selectAll } from '../../stores/schedulingInstances';
import { useAppDispatch, useAppSelector } from '../../stores/store';
import { t, T } from '../../i18n';
import { returnCheckedItemIds } from '../../utils/searchSelectUtils';
import { GenericModal } from '../common/GenericModal';
import { ModalTitle } from '../common/ModalTitle';
import { SearchAndSelect } from '../common/SearchAndSelect';

interface UserChangeUserPermissionModalProps {
  readonly orgUserId: number;
  readonly permissionData: AddUserToSchedulingInstanceData & {schedulingInstanceId: number;};
  readonly closeModal: () => void;
  readonly refresh?: () => void;
}

interface AddUserToInstanceForm {
  schedulingInstanceId: number;
  permission: string;
  allowExports: boolean;
  allItems: boolean;
  items: SearchSelectItem[];
  totalItems: number;
}

export const UserChangeUserPermissionModal = ({
  orgUserId,
  permissionData,
  closeModal,
  refresh
}: UserChangeUserPermissionModalProps) => {
  const { fontSizes, colors } = useTheme();
  const dispatch = useAppDispatch();
  const { isMobile } = usePlatformBreakpoint();

  const { enterpriseId, schedulingInstances } = useAppSelector((state: RootState) => ({
    enterpriseId: selectEnterprise(state.currentUser)?.id,
    schedulingInstances: selectAll(state.schedulingInstances)
  }));
  const schedulingInstanceId = permissionData.schedulingInstanceId;

  const fetchCalendars = async (eid: number, options?: PageInfoQueryWithSearch) => {
    const res = await fetchSchedulingInstanceCalendars(eid, schedulingInstanceId, options);
    const calendars = res.data.map((calendar) => ({
      itemId: calendar.id,
      name: calendar.name,
      checked: permissionData.calendars.includes(calendar.id)
    }));
    return {
      data: calendars,
      pageInfo: res.pageInfo
    };
  };

  const formMethods = useForm<AddUserToInstanceForm>({
    defaultValues: async () => {
      if (enterpriseId) {
        const calendars = await fetchCalendars(enterpriseId);
        return {
          schedulingInstanceId,
          permission: permissionData.permissions,
          allowExports: permissionData.allowExports,
          allItems: permissionData.allCalendars,
          items: calendars.data,
          totalItems: permissionData.calendars.length
        };
      }
      return {
        schedulingInstanceId: 0,
        permission: '',
        allowExports: false,
        allItems: false,
        items: [],
        totalItems: 0
      };
    }
  });

  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
    reset,
    setValue,
    watch
  } = formMethods;

  const allowExportsRegister = register('allowExports');

  // Because Rosetta's built-in onChange function doesn't play well with react-hook-form, and we need these values
  //   for other checks, we watch them instead of using the expanded `register` function
  const selectedPermission = watch('permission');
  const allowExports = !!watch('allowExports');

  register('permission', { required: t("Please choose permission level", {}, { project: 'enterprise-dashboard' }) });

  const triggerCloseModal = () => {
    closeModal();
    reset();
  };

  const submitUserToInstance = handleSubmit((formData) => {
    const instance = schedulingInstances.find((i) => i.id === schedulingInstanceId);

    if (instance) {
      const calendars = !formData.allItems ? returnCheckedItemIds(formData.items) : [];

      const data: AddUserToSchedulingInstanceData = {
        permissions: formData.permission,
        allowExports: Boolean(formData.allowExports),
        calendars: calendars || [],
        allCalendars: formData.allItems
      };
      if (enterpriseId) {
        updateUserInSchedulingInstance(enterpriseId, schedulingInstanceId, orgUserId, data).
        then(() => {
          dispatch(
            sendSuccessMessage(t("User permission has been updated", null, { project: 'enterprise-dashboard' }))
          );
          dispatch(fetchSchedulingInstances(enterpriseId));
          triggerCloseModal();
          if (refresh) {
            refresh();
          }
        }).
        catch(() => {
          dispatch(
            sendErrorMessage(t("There was a problem updating the user's permission",
            null, {
              project: 'enterprise-dashboard' })

            )
          );
        });
      }
    } else {
      dispatch(
        sendErrorMessage(t("Unable to find scheduling instance data", null, { project: 'enterprise-dashboard' }))
      );
    }
  });

  // NOTE: this maxHeight calculation is a near estimate of the height of the rest of the values in the containing modal
  const searchSelectPaddingBottom = isMobile ? '100px' : '';
  return (
    <GenericModal
      closeModal={triggerCloseModal}
      modalActions={
      <Flex justifyContent="space-between" mt={isMobile ? 0 : 6}>
          <Button.Tertiary type="button" onClick={triggerCloseModal} size="medium">
            <T project="enterprise-dashboard">{"Cancel"}</T>
          </Button.Tertiary>
          <Button.Primary type="submit" size="medium" onClick={submitUserToInstance} disabled={!isDirty}>
            <T project="enterprise-dashboard">{"Save"}</T>
          </Button.Primary>
        </Flex>}>


      <FormProvider {...formMethods}>
        <ModalTitle>
          <T project="enterprise-dashboard">{"Change user permission"}</T>
        </ModalTitle>
        <Divider />
        <Text.Body fontWeight={500} mt={5} mb={0}>
          <T project="enterprise-dashboard">{"Permission"}</T>
        </Text.Body>
        <Dropdown
          placement="bottom"
          onChange={(v: string) => setValue('permission', v, { shouldDirty: true })}
          value={selectedPermission}>

          <Dropdown.Option
            value="admin"
            description={
            <Text.Caption>
                <T project="enterprise-dasboard">{"Admins have full access to a scheduling instance with the exception of deleting the instance."}</T>


              </Text.Caption>}>


            <Text.Body m={0} fontSize={fontSizes[3]} fontWeight={500}>
              <T project="enterprise-dashboard">{"Admin"}</T>
            </Text.Body>
          </Dropdown.Option>
          <Dropdown.Option
            value="edit"
            description={
            <Text.Caption>
                <T project="enterprise-dasboard">{"These users have limited access to view and edit a scheduling instance, which can be further restricted to certain calendars."}</T>



              </Text.Caption>}>


            <Text.Body m={0} fontSize={fontSizes[3]} fontWeight={500}>
              <T project="enterprise-dashboard">{"View & Edit"}</T>
            </Text.Body>
          </Dropdown.Option>
          <Dropdown.Option
            value="view"
            description={
            <Text.Caption>
                <T project="enterprise-dasboard">{"These users have limited access to view scheduling instances, which can be further restricted to certain calendars."}</T>



              </Text.Caption>}>


            <Text.Body m={0} fontSize={fontSizes[3]} fontWeight={500}>
              <T project="enterprise-dashboard">{"View Only"}</T>
            </Text.Body>
          </Dropdown.Option>
        </Dropdown>
        {errors.permission &&
        <Text.Caption sx={{ color: colors.red[400] }} role="alert">
            {errors.permission.message}
          </Text.Caption>}

        {(selectedPermission === 'view' || selectedPermission === 'edit') && schedulingInstanceId ?
        <Box position="relative">
            <Text.Body fontWeight={500} mt={5} mb={0}>
              <T project="enterprise-dashboard">{"Export Settings"}</T>
            </Text.Body>
            <Text.Body as="label" css={{ display: 'inline-flex' }} mt={2}>
              <Checkbox
              ref={allowExportsRegister.ref}
              name="allowExports"
              checked={allowExports}
              onChange={async (v: boolean, e: ChangeEvent<HTMLInputElement>) => {
                await allowExportsRegister.onChange(e);
              }}
              value={true}
              mr={2} />

              <T project="enterprise-dashboard">{"Allow user to export clients and appointments"}</T>
            </Text.Body>
            <Text.Body fontWeight={500} mt={5}>
              <T project="enterprise-dashboard">{"Calendar Access"}</T>
            </Text.Body>

            <SearchAndSelect
            searchPlaceholder={t("Search calendars", {}, { project: 'enterprise-dashboard' })}
            allCheckText={t("All Calendars", {}, { project: 'enterprise-dashboard' })}
            emptyText={t("There are no calendars associated with this instance.",

            {}, {
              project: 'enterprise-dashboard' })}

            listItems={fetchCalendars}
            additionalPaddingBottom={searchSelectPaddingBottom} />

          </Box> :

        <></>}

      </FormProvider>
    </GenericModal>);

};