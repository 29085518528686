import { ActionList } from '@sqs/rosetta-compositions';
import { Divider, Stack } from '@sqs/rosetta-elements';
import { ExternalLink } from '@sqs/rosetta-icons';
import { Flex, Text } from '@sqs/rosetta-primitives';
import React from 'react';
import { usePlatformBreakpoint } from '../../hooks/usePlatformBreakpoint';
import { useSchedulingInstanceActions } from '../../hooks/useSchedulingInstanceActions';
import { t, T } from '../../i18n';
import { SchedulingInstance } from '../../models/SchedulingInstance';
import { ORG_MANAGER } from '../../models/SqspUser';
import { selectEnterprise } from '../../stores/currentUser';
import { RootState } from '../../stores/rootReducer';
import { useAppSelector } from '../../stores/store';

interface SchedulingInstanceListActionMenuProps {
  readonly schedulingInstance: SchedulingInstance;
  readonly openDeleteModal: (schedulingInstance: SchedulingInstance) => void;
}

export const SchedulingInstanceListActionMenu = ({
  schedulingInstance,
  openDeleteModal
}: SchedulingInstanceListActionMenuProps) => {
  const { isMobile } = usePlatformBreakpoint();
  const { viewSchedulingInstanceAsUser } = useSchedulingInstanceActions();

  const { enterpriseId, currentUser } = useAppSelector((state: RootState) => ({
    isLoading: state.currentUser.isLoading,
    currentUser: state.currentUser.currentUser,
    enterpriseId: selectEnterprise(state.currentUser)?.id
  }));

  const viewSchedulingInstance = (onRequestClose: () => void) => {
    if (!enterpriseId || !schedulingInstance.id) {
      return;
    }
    if (currentUser.role === ORG_MANAGER) {
      viewSchedulingInstanceAsUser(schedulingInstance).finally(() => {
        onRequestClose();
      });
    } else if (schedulingInstance.adminUrl) {
      window.location.href = schedulingInstance.adminUrl;
      onRequestClose();
    }
  };

  const openSchedulingInstanceNewTab = () => {
    window.open(schedulingInstance.clientUrl, '_blank');
  };

  const ariaLabel = t("Open menu", {}, { project: 'enterprise-dashboard' });

  const actionItems = ({ onRequestClose, mobile }: {onRequestClose: () => void;mobile?: boolean;}) => {
    return (
      <Stack bg="white" py={isMobile ? 0 : 1} divider={mobile && <Divider as="div" role="presentation" />}>
        <ActionList.Item
          as="div"
          onClick={() => {
            viewSchedulingInstance(onRequestClose);
          }}>

          <Flex justifyContent="space-between" aria-live="polite">
            <T project="enterprise-dashboard">{"View Scheduling Instance"}</T>
          </Flex>
        </ActionList.Item>
        <ActionList.Item
          as="div"
          onClick={() => {
            onRequestClose();
            openSchedulingInstanceNewTab();
          }}>

          <Flex justifyContent="flex-start" alignItems="center" gap="1">
            <T project="enterprise-dashboard">{"Open scheduling page"}</T> <ExternalLink sx={{ height: '20px' }} />
          </Flex>
        </ActionList.Item>
        {currentUser?.role === ORG_MANAGER &&
        <ActionList.Item
          as="div"
          onClick={() => {
            onRequestClose();
            openDeleteModal(schedulingInstance);
          }}
          color="red.300">

            <T project="enterprise-dashboard">{"Delete Scheduling Instance"}</T>
          </ActionList.Item>}

      </Stack>);

  };

  if (isMobile) {
    return (
      <ActionList.Sheet
        aria-label={ariaLabel}
        renderHeader={() =>
        <Text.Body textAlign="center" fontWeight={500} pt={3}>
            {schedulingInstance.instanceName}
          </Text.Body>}>


        {({ onRequestClose }) => actionItems({ onRequestClose, mobile: true })}
      </ActionList.Sheet>);

  }

  return (
    <ActionList.PopOver aria-label={ariaLabel} anchorPoint={{ x: 'center', y: 'top' }} position="bottom">
      {({ onRequestClose }) => actionItems({ onRequestClose })}
    </ActionList.PopOver>);

};