import { Modal, Stack } from '@sqs/rosetta-elements';
import { Box, Button, Flex, Text } from '@sqs/rosetta-primitives';
import { useTheme } from '@sqs/rosetta-styled';
import React, { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FEATURE_FLAGS } from '../../const/feature-flags';
import { usePlatformBreakpoint } from '../../hooks/usePlatformBreakpoint';
import { selectEnterprise } from '../../stores/currentUser';
import { createInvitedOrgUser } from '../../stores/invitedOrgUsers';
import { createOrgUser } from '../../stores/orgUsers';
import { RootState } from '../../stores/rootReducer';
import { useAppDispatch, useAppSelector } from '../../stores/store';
import { T, t } from '../../i18n';
import { preventSubmit } from '../../utils/keyboard';
import { AcuityTextField } from '../common/AcuityTextField';
import { GenericModal } from '../common/GenericModal';
import { ModalTitle } from '../common/ModalTitle';
import { FeatureFlagContext } from '../FeatureFlagProvider';
import { UserRoleDropdown } from '../Users/UserRoleDropdown';
import { Enterprise } from '../../models/Enterprise';
import { ORG_MEMBER, SqspUser } from '../../models/SqspUser';

interface CreateUserModalProps {
  readonly closeModal: () => void;
  readonly isOpen: boolean;
}

interface NewUserForm {
  readonly name: string;
  readonly email: string;
  readonly orgRole: string;
}

export const CreateUserModal = ({ closeModal, isOpen }: CreateUserModalProps) => {
  const dispatch = useAppDispatch();
  const { isMobile } = usePlatformBreakpoint();
  const { colors, fontSizes, radii, space } = useTheme();
  const { flagEnabled } = useContext(FeatureFlagContext);
  // TODO: Remove with AE-634
  const filterUsersByInviteStatus = flagEnabled(FEATURE_FLAGS.ENTERPRISE_SEPARATE_INVITED_USER);

  const { enterprise, currentUser } = useAppSelector((state: RootState) => ({
    enterprise: selectEnterprise(state.currentUser) as Enterprise,
    currentUser: state.currentUser.currentUser as SqspUser
  }));
  const enterpriseId = enterprise.id;

  const formMethods = useForm<NewUserForm>({
    defaultValues: {
      name: '',
      email: '',
      orgRole: ''
    }
  });
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
    watch
  } = formMethods;

  const triggerCloseModal = () => {
    reset();
    closeModal();
  };

  const sendInvite = handleSubmit((formData) => {
    if (enterpriseId) {
      if (filterUsersByInviteStatus) {
        dispatch(createInvitedOrgUser(enterpriseId, formData));
      } else {
        dispatch(createOrgUser(enterpriseId, formData));
      }
      triggerCloseModal();
    }
  });

  register('name', {
    required: t("Please enter a name", {}, { project: 'enterprise-dashboard' }),
    minLength: {
      value: 2,
      message: t("Please enter a name longer than 2 characters", {}, { project: 'enterprise-dashboard' })
    }
  });
  register('email', {
    required: t("Please enter a valid email", {}, { project: 'enterprise-dashboard' }),
    minLength: {
      value: 2,
      message: t("Please enter a valid email", {}, { project: 'enterprise-dashboard' })
    },
    pattern: {
      value: /\S+@\S+\.\S+/,
      message: t("Please enter a valid email", {}, { project: 'enterprise-dashboard' })
    }
  });

  return (
    <Modal.Transition>
      {isOpen &&
      <GenericModal
        closeModal={triggerCloseModal}
        modalActions={
        <Flex justifyContent="space-between" mt={isMobile ? 0 : 6}>
              <Button.Tertiary type="button" size="medium" onClick={triggerCloseModal}>
                <T project="enterprise-dashboard">{"Cancel"}</T>
              </Button.Tertiary>
              <Button.Primary size="medium" type="submit" onClick={sendInvite}>
                <T project="enterprise-dashboard">{"Invite"}</T>
              </Button.Primary>
            </Flex>}>


          <FormProvider {...formMethods}>
            <ModalTitle>
              <T project="enterprise-dashboard">{"Invite User"}</T>
            </ModalTitle>
            <Stack>
              <Box mb={3}>
                <Text.Label>
                  <T project="enterprise-dashboard">{"Name"}</T>
                  <AcuityTextField
                  inputProps={{
                    id: 'name',
                    onChange: (v: string) => setValue('name', v, { shouldDirty: true }),
                    value: watch('name'),
                    onKeyDown: preventSubmit
                  }} />

                </Text.Label>
                {errors.name &&
              <Text.Caption color="red.400" role="alert">
                    {errors.name.message}
                  </Text.Caption>}

              </Box>
              <Box mb={3}>
                <Text.Label>
                  <T project="enterprise-dashboard">{"Email"}</T>
                  <AcuityTextField
                  inputProps={{
                    id: 'email',
                    onChange: (v: string) => setValue('email', v, { shouldDirty: true }),
                    value: watch('email'),
                    onKeyDown: preventSubmit
                  }} />

                </Text.Label>
                {errors.email &&
              <Text.Caption color="red.400" role="alert">
                    {errors.email.message}
                  </Text.Caption>}

              </Box>
              <Box>
                <Text.Label>
                  <T project="enterprise-dashboard">{"Role"}</T>
                </Text.Label>
                <Text.Caption m={0} fontSize={fontSizes[3]} color={colors.gray[400]}>
                  <T project="enterprise-dasboard">{"This is the user\u2019s role within the organization"}</T>
                </Text.Caption>
                <UserRoleDropdown
                currentRole={ORG_MEMBER} // so the conditional banner appears when selecting Manager
              />
              </Box>
            </Stack>
          </FormProvider>
        </GenericModal>}

    </Modal.Transition>);

};